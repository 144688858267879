import { useTranslation } from 'react-i18next';

import { Tier } from '@agentero/models/tier';
import { Tag, TagColor } from '@agentero/ui';

const statusColorMapping: { [key in Exclude<Tier, Tier.Unspecified>]: TagColor } = {
	[Tier.Bronze]: 'blue',
	[Tier.Silver]: 'orange',
	[Tier.Gold]: 'purple'
};

type TierStatusLabelProps = {
	tier: Tier;
	isPaymentFailed?: boolean;
	onClick?: () => void;
};

export const TierStatusLabel = ({ tier, isPaymentFailed, onClick }: TierStatusLabelProps) => {
	const { t } = useTranslation();

	const label = isPaymentFailed ? t(`tier.paymentFailed`) : t(`tier.${tier}`);

	return tier !== Tier.Unspecified ? (
		<Tag color={statusColorMapping[tier]} aria-label={label} onClick={onClick} role="status">
			{label}
		</Tag>
	) : null;
};
